




























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { getPropertyHistory } from '@/api/properties'
import Pagination from '@/components/Pagination/index.vue'
import {
  createFilter,
  FilterOperator,
  FilterType, getSortItems,
  prepareListFilters
} from '@/utils/filter'
import { getTranslations, parseTime } from '@/utils'

@Component({
  name: 'PropertyHistory',
  components: {
    Pagination
  }
})

export default class extends Vue {
  @Prop({ required: true }) private property!: any
  @Prop({ required: true }) private field!: string
  private total = 0
  private list = []
  private listLoading = false
  private parseTime = parseTime
  private getTranslations = getTranslations
  private listQuery = createFilter()

  created() {
    this.getList()
  }

  private prepareFilters() {
    prepareListFilters({
      listQuery: this.listQuery,
      withDefaultSort: true,
      search: '',
      searchFields: [],
      filters: [],
      callback: () => {
        this.listQuery.query?.filters?.items?.push({
          type: FilterType.field,
          key: 'property_id',
          value: this.property.id,
          operator: FilterOperator.eq
        })
        this.listQuery.query?.filters?.items?.push({
          type: FilterType.field,
          key: 'field',
          value: this.field,
          operator: FilterOperator.eq
        })
      }
    })
  }

  private getValue(row: any, key = '') {
    if (row.field === 'status') {
      return this.$t(`property_status.${row.values[key]}`)
    }
    return row.values[key]
  }

  private handleSortChange(params: any = {}) {
    if (this.listQuery?.query?.sort) {
      this.listQuery.query.sort = getSortItems(params)
    }
    this.getList()
  }

  private async getList() {
    this.listLoading = true
    try {
      this.prepareFilters()
      const { data } = await getPropertyHistory(this.listQuery)
      this.list = data.collection || []
      this.total = data.pagination.total_items || 0
    } catch (err) {}
    this.listLoading = false
  }
}
